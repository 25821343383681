$breakpoints: (
  "xs": 375px,
  "ss": 524px,
  "sm": 768px,
  "md": 992px,
  "lg": 1300px,
  "xl": 1800px,
);

$colors: (
  redPrimary: #d81d34,
  greenPrimary: #1fba9b,
  purplePrimary: #4d2650,
  grayPrimary: #272626,
  grayPrimary02: #464646,
  grayPrimary03: #7c7c7c,
  grayPrimary04: #efefef,
  grayPrimary05: #f7f7f7,
  whiteColor: #fff,
  blackColor: #000,
);

$spaces: (
  xxxs: 4px,
  xxs: 8px,
  xs: 12px,
  s: 16px,
  m: 24px,
  l: 32px,
  xl: 48px,
  xxl: 72px,
  xxxl: 96px,
  xxxxl: 120px,
);

$textFamily: (
  font-gotham-pro: (
    "Gotham Pro",
    sans-serif,
  ),
  font-gabriela: (
    "Gabriela",
    sans-serif,
  ),
  font-roboto: (
    "Roboto Condensed",
    sans-serif,
  ),
);

$textSizes: (
  xxl: 32px,
  xl: 28px,
  l: 24px,
  m: 22px,
  s: 20px,
  xs: 16px,
  xxs: 14px,
  xxxs: 8px,
);

$textWeights: (
  light: 100,
  regular: 300,
  medium: 400,
  bold: 600,
  black: 700,
);

$transitions: (
  default: all 0.2s ease-in-out,
  fast: all 0.1s ease-in-out,
);
