@import "../_config/index";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;

  & > li {
    border-radius: 6px;
    overflow: hidden;
    margin: 0 2px;

    a,
    span {
      height: 40px;
      padding: 0 12px;
      margin: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      line-height: normal;
      background: none;
      float: none;
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xxs);
      font-weight: text-weight(medium);
      color: color(greenPrimary);
    }

    a {
      color: color(grayPrimary);

      &:hover {
        background-color: color(grayPrimary04);
        color: color(grayPrimary);
      }
    }

    &.active {
      &:hover,
      span {
        cursor: not-allowed;
        background-color: color(redPrimary) !important;
        color: color(whiteColor) !important;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
