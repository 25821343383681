.zcp {
    margin-bottom: 40px;
    
    .zcp-title {
        margin-bottom: 20px;
        font-family: text-family(font-gotham-pro);
        font-size: 16px;
        font-weight: 400;
        color: #464646;
    }
    
    .zcp-form-panel {
        display: flex;
        
        .zcp-form-input {
            text-align: center;
            width: 240px;
            height: 40px;
            
            &:disabled {
                cursor: wait;
            }
        }
    }
    
    .zcp-submit-btn,
    .zcp-submit-btn:hover,
    .zcp-submit-btn:focus,
    .zcp-submit-btn:active{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 90%;
        color: white;
        background-color: #1fba9b;
        border-radius: 4px;
        height: 40px;
        width: 36px;
        padding: 0;
        margin-left: 8px;
        
        &:disabled {
            cursor: wait;
        }
    }
}