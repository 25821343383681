@import "../_config/index";

.slides-home {
  transition: transition(fast);
  margin-bottom: 30px;

  @include breakpoint("sm") {
    margin-bottom: 40px;
  }

  @include breakpoint("lg") {
    margin-bottom: 60px;
  }
}

.banners-area-2 {
  display: none;
  margin-bottom: 40px;
  position: relative;

  @include breakpoint("sm") {
    display: block;
  }

  &-item {
    figure {
      width: 100%;
      height: 420px;
      display: block;
      margin-bottom: 10px;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
      transition: transition(default);

      a,
      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      img {
        object-fit: cover;
        transition: transition(default);
      }
    }

    h4 {
      margin-bottom: 0;

      a {
        display: block;
        font-family: text-family(font-gotham-pro);
        font-size: text-size(l);
        font-weight: text-weight(medium);
        color: color(redPrimary);
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  &-mobile {
    display: block;
    margin-bottom: 10px;
    position: relative;

    @include breakpoint("sm") {
      display: none;
    }

    figure {
      margin-bottom: 10px;
    }

    h4 {
      &,
      & a {
        display: block;
        font-family: text-family(font-gotham-pro);
        font-size: text-size(xs);
        font-weight: text-weight(medium);
        color: color(redPrimary);
      }
    }
  }

  &-carousel {
    position: relative;

    .owl-nav {
      left: -10px;
      right: -10px;
      transform: translateY(calc(-50% - 30px));

      button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color(redPrimary) !important;

        span {
          display: inline-block;
          line-height: 20px;
          font-size: 24px;
          color: #fff;
        }
      }
    }
  }
}

.banners-area-3 {
  margin-bottom: 60px;
  position: relative;

  .owl-stage-outer {
    height: 210px;
    overflow: hidden;
  }

  li {
    position: relative;

    figure {
      height: 210px;
      display: block;
      overflow: hidden;
      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        transition: transition(default);
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .owl-nav {
    left: -10px;
    right: -10px;

    @include breakpoint("sm") {
      left: -16px;
      right: -16px;
    }

    button {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: color(redPrimary) !important;

      span {
        display: inline-block;
        line-height: 20px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}

.banners-area-4 {
  $videoSize: 730px;

  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;

  @include breakpoint("sm") {
    height: 267px;
    margin-bottom: 40px;
    flex-direction: row;
  }

  @include breakpoint("lg") {
    margin-bottom: 60px;
    height: 469px;
  }

  & > .video,
  & > .cover {
    height: 300px;

    @include breakpoint("sm") {
      height: 100%;
    }
  }

  & > .video {
    width: 100%;
    margin-bottom: 20px;

    @include breakpoint("sm") {
      width: 413px;
      margin-bottom: 0;
    }

    @include breakpoint("lg") {
      width: $videoSize;
      margin-bottom: 0;
    }

    p,
    iframe {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  & > .cover {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent
      radial-gradient(
        closest-side at 75% 24%,
        #ffffff 0%,
        #fafafa 22%,
        #f8f8f8 52%,
        #e8e8e8 78%,
        #e5e5e5 100%
      )
      0% 0% no-repeat padding-box;
    border-radius: 10px;

    @include breakpoint("sm") {
      width: calc(100% - 413px - 15px);
      height: 100%;
    }

    @include breakpoint("lg") {
      width: calc(100% - #{$videoSize} - 15px);
      height: 100%;
    }

    &,
    & a {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 260px;

        @include breakpoint("sm") {
          height: auto;
        }
      }
    }
  }
}

.banners-area-5 {
  display: none;

  @include breakpoint("sm") {
    width: 100%;
    display: grid;
    margin-bottom: 60px;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 15px;
  }

  &-carousel {
    .owl-nav {
      left: -10px;
      right: -10px;
      transform: translateY(-50%);

      button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color(redPrimary) !important;

        span {
          display: inline-block;
          line-height: 20px;
          font-size: 24px;
          color: #fff;
        }
      }
    }
  }

  &,
  &-mobile {
    li {
      height: 200px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      figure {
        position: relative;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          transition: transition(default);
        }

        &,
        & a {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
        }
      }

      span {
        width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(#7c7c7c, 5.6);
        border: 1px solid #9e9e9e;
        border-radius: 4px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        backdrop-filter: blur(2px);
        text-transform: uppercase;
        font-family: text-family(font-gotham-pro);
        font-size: text-size(xxs);
        font-weight: text-weight(medium);
        color: color(whiteColor);
        opacity: 0.9;
        position: absolute;
        z-index: 2;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &-mobile {
    display: block;
    margin-bottom: 30px;
    position: relative;

    @include breakpoint("sm") {
      display: none;
    }
  }
}

.banners-area-6 {
  display: none;

  @include breakpoint("sm") {
    width: 100%;
    display: grid;
    margin-bottom: 60px;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 15px;
  }

  &-carousel {
    .owl-nav {
      left: -10px;
      right: -10px;
      transform: translateY(-50%);

      button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color(redPrimary) !important;

        span {
          display: inline-block;
          line-height: 20px;
          font-size: 24px;
          color: #fff;
        }
      }
    }
  }

  &,
  &-mobile {
    li {
      height: 200px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      figure {
        position: relative;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          transition: transition(default);
        }

        &,
        & a {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
        }
      }

      span {
        width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(#7c7c7c, 5.6);
        border: 1px solid #9e9e9e;
        border-radius: 4px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        backdrop-filter: blur(2px);
        text-transform: uppercase;
        font-family: text-family(font-gotham-pro);
        font-size: text-size(xxs);
        font-weight: text-weight(medium);
        color: color(whiteColor);
        opacity: 0.9;
        position: absolute;
        z-index: 2;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &-mobile {
    display: block;
    margin-bottom: 30px;
    position: relative;

    @include breakpoint("sm") {
      display: none;
    }
  }
}