@import "../_config/index";

.page-nav {
  margin-bottom: 40px;
  justify-content: space-between;
  flex-direction: column;

  &,
  &-left,
  &-right {
    display: flex;
    align-items: initial;

    @include breakpoint("sm") {
      align-items: center;
    }
  }

  @include breakpoint("sm") {
    flex-direction: row;
  }

  &-left {
    width: 100%;
    margin-bottom: 20px;
    justify-content: left;

    @include breakpoint("sm") {
      width: calc(50% - 10px);
      margin-bottom: 0px;
    }
  }

  &-right {
    width: 100%;
    justify-content: left;

    @include breakpoint("sm") {
      width: calc(50% - 10px);
      justify-content: right;
    }
  }
}

.breadcrumb {
  background: color(whiteColor);
  margin: 0;
  border-radius: 0;

  &,
  & > div,
  & > ul,
  & > ul > li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  a {
    display: flex;
    align-items: center;
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xs);
    color: color(grayPrimary03);
  }

  & > div {
    margin-right: 10px;

    &:after {
      margin-left: 10px;
      display: block;
      content: "|";
      font-family: text-family(font-gotham-pro);
      font-size: text-size(m);
      color: color(grayPrimary03);
    }

    & > a {
      text-transform: uppercase;
      font-weight: text-weight(bold);
      color: color(grayPrimary02);

      &:before {
        width: 19px;
        height: 18px;
        display: block;
        margin-right: 10px;
        background: url(image("icon-back.png")) no-repeat center;
        transform: translateY(-2px);
        content: "";
      }
    }
  }

  & > ul {
    & > li {
      & + li {
        margin-left: 10px;
        font: normal normal normal 20px/1 FontAwesome;

        &:before {
          content: "\f105";
          margin-right: 10px;
        }
      }

      a {
        display: block;
        font-weight: text-weight(light);
        color: color(grayPrimary03);
      }
    }
  }
}

.change-column {
  display: none;

  @include breakpoint("sm") {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  & > a {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 0 3px;
    cursor: pointer;

    & > span {
      width: 6px;
      height: 16px;
      display: block;
      background: #dbdbdb;
      margin: 0 2px;
      transition: transition(fast);
    }

    &:hover {
      & > span {
        background: lighten(color(redPrimary), 20%);
      }
    }

    &#column-item-active {
      & > span {
        background: color(redPrimary);
      }
    }
  }

  &-mobile {
    display: none;
  }
}

.sort-by__select {
  & > .sort-by__options {
    padding: 0 10px;

    &,
    &:hover {
      min-width: 180px;
      display: block;
      border-radius: 6px;
      border: solid 1px color(grayPrimary03);
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xxs);
      font-weight: text-weight(light);
      color: color(grayPrimary03);
    }
  }
}
