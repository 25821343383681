@import "../_config/index";

.showcases-slider {
  position: relative;
  padding: 0;

  .owl-nav {
    left: -60px;
    right: -60px;

    button {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: color(redPrimary) !important;

      span {
        display: inline-block;
        line-height: 20px;
        font-size: 24px;
        color: #fff;
      }
    }
  }

  .owl-dots {
    display: none;
  }
}
