@import '../_config/index';

.title {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  font-family: text-family(font-gotham-pro);
  font-weight: text-weight(bold);
  font-size: text-size(m);
  color: color(grayPrimary02);
  
  &-banners-area-5 {
    text-align: center;
    font-family: text-family(font-gotham-pro);
    font-weight: text-weight(bold);
    font-size: text-size(m);

    @include breakpoint('md') {
      margin-bottom: 20px;
      font-size: text-size(l);
    }

    @include breakpoint('lg') { 
      margin-bottom: 30px;
      font-size: text-size(xl);
    }
    
  }

  &-banners-area-6 {
    text-align: center;
    font-family: text-family(font-gotham-pro);
    font-weight: text-weight(bold);
    font-size: text-size(m);

    @include breakpoint('md') {
      margin-bottom: 20px;
      font-size: text-size(l);
    }

    @include breakpoint('lg') { 
      margin-bottom: 30px;
      font-size: text-size(xl);
    }
    
  }

  @include breakpoint('md') {
    margin-bottom: 20px;
    font-size: text-size(l);
  }

  @include breakpoint('lg') {
    margin-bottom: 30px;
    font-size: text-size(xl);
  }

  span {
    color: color(redPrimary);
  }
}
