@import "../_config/index";

body {
  transition: transition(fast);
}

.header {
  display: flex;
  flex-direction: column;
  background: color(whiteColor);
  transition: transition(fast);

  &.header-fixed {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10;
    transition: transition(fast);
    &> .top-strip {
      display: block;
    }

  }

  .header-content {
    order: 2;
  }

  .top-strip {
    order: 1;

    &,
    a {
      color: color(whiteColor);
    }
  }
}

.header-content {
  background: color(whiteColor);

  &-wrapper {
    display: flex;
    padding: space(m) 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include breakpoint("sm") {
      flex-wrap: nowrap;
    }

    .logo {
      order: 1;
    }

    .form-search {
      width: 100%;
      order: 3;
      margin-top: 20px;

      @include breakpoint("sm") {
        width: auto;
        order: 2;
        margin-top: 0px;
      }
    }

    .menu {
      order: 2;

      @include breakpoint("sm") {
        order: 3;
      }
    }
  }

  .form-search {
    display: flex;

    @include breakpoint("lg") {
      display: none;
    }
  }
}
