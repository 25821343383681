@charset "UTF-8";
.footer {
  position: relative;
  background: #f7f7f7; }

.footer-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  position: relative; }
  .footer-top > .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 2; }
    @media (min-width: 524px) {
      .footer-top > .wrapper {
        transition: all 0.2s ease-in-out;
        flex-direction: row; } }
    .footer-top > .wrapper .column {
      margin-bottom: 10px; }
      @media (min-width: 524px) {
        .footer-top > .wrapper .column {
          transition: all 0.2s ease-in-out;
          margin-bottom: 0px; } }
  @media (min-width: 375px) {
    .footer-top .expandable-menu.show-only-smartphones {
      transition: all 0.2s ease-in-out;
      display: none; } }
  .footer-top .expandable-menu.hidden-only-smartphones {
    display: none; }
    @media (min-width: 524px) {
      .footer-top .expandable-menu.hidden-only-smartphones {
        transition: all 0.2s ease-in-out;
        display: block; } }
  .footer-top .expandable-menu h4 {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px;
    font-family: "Gotham Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #464646;
    cursor: pointer;
    position: relative; }
    @media (min-width: 524px) {
      .footer-top .expandable-menu h4 {
        transition: all 0.2s ease-in-out;
        cursor: default;
        display: block !important; } }
    .footer-top .expandable-menu h4:before {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 22px;
      font-weight: 400;
      transition: all 0.2s ease-in-out;
      position: absolute;
      content: "›"; }
      @media (min-width: 524px) {
        .footer-top .expandable-menu h4:before {
          transition: all 0.2s ease-in-out;
          top: -4px !important;
          transform: rotate(90deg) !important; } }
    .footer-top .expandable-menu h4.expanded-footer:before {
      top: -4px;
      transform: rotate(90deg); }
  .footer-top .list-links {
    line-height: 24px;
    display: block;
    padding-left: 20px; }
    @media (min-width: 524px) {
      .footer-top .list-links {
        transition: all 0.2s ease-in-out;
        line-height: 32px;
        padding-left: 0;
        display: block !important; } }
    .footer-top .list-links li a {
      display: block;
      font-family: "Gotham Pro", sans-serif;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase; }
  .footer-top .social-links h4 {
    font-family: "Gotham Pro", sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase; }
  .footer-top .social-links ul {
    display: flex;
    padding-left: 0px; }
    @media (min-width: 524px) {
      .footer-top .social-links ul {
        transition: all 0.2s ease-in-out;
        display: block;
        padding-left: 20px; } }
    .footer-top .social-links ul li {
      margin-right: 8px;
      margin-bottom: 0px; }
      @media (min-width: 524px) {
        .footer-top .social-links ul li {
          transition: all 0.2s ease-in-out;
          margin-right: 0px;
          margin-bottom: 8px; } }
      .footer-top .social-links ul li a {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .footer-top .social-links ul li a > span,
        .footer-top .social-links ul li a > i {
          font-size: 26px; }
  .footer-top .footer-logo {
    align-self: center;
    display: none; }
    @media (min-width: 524px) {
      .footer-top .footer-logo {
        transition: all 0.2s ease-in-out;
        display: block; } }
    .footer-top .footer-logo img {
      max-width: 300px;
      max-height: 60px; }
  .footer-top .back-to-top {
    margin-bottom: 50px;
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f7f7f7;
    order: 1; }
    @media (min-width: 524px) {
      .footer-top .back-to-top {
        transition: all 0.2s ease-in-out;
        display: flex; } }
    .footer-top .back-to-top:after {
      display: block;
      height: 1px;
      background: #dedede;
      top: 50%;
      left: 0;
      right: 0;
      content: "";
      position: absolute;
      z-index: 1; }
    .footer-top .back-to-top .button-go-top {
      width: 180px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1fba9b;
      border-radius: 4px;
      position: relative;
      border: none;
      z-index: 9;
      transition: all 0.1s ease-in-out; }
      .footer-top .back-to-top .button-go-top:hover {
        padding-bottom: 5px; }
      .footer-top .back-to-top .button-go-top:before, .footer-top .back-to-top .button-go-top:after {
        width: 23px;
        background: #f7f7f7;
        top: 0;
        bottom: 0;
        display: block;
        content: "";
        position: absolute; }
      .footer-top .back-to-top .button-go-top:before {
        left: -23px; }
      .footer-top .back-to-top .button-go-top:after {
        right: -23px; }
      .footer-top .back-to-top .button-go-top,
      .footer-top .back-to-top .button-go-top * {
        text-decoration: none !important; }
      .footer-top .back-to-top .button-go-top > span,
      .footer-top .back-to-top .button-go-top > i {
        color: #fff; }
      .footer-top .back-to-top .button-go-top > span {
        font-size: 14px;
        font-weight: medium;
        color: #fff; }
      .footer-top .back-to-top .button-go-top > i {
        margin-left: 10px; }

.footer-bottom {
  background: #272626; }
  .footer-bottom > .wrapper {
    height: auto;
    display: flex;
    padding: 20px 41px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 524px) {
      .footer-bottom > .wrapper {
        transition: all 0.2s ease-in-out;
        height: 62px;
        flex-direction: row;
        padding: 0; } }
    .footer-bottom > .wrapper,
    .footer-bottom > .wrapper > a {
      font-family: "Gotham Pro", sans-serif;
      font-weight: 300;
      font-size: 14px;
      color: #fff; }
    .footer-bottom > .wrapper .footer-slogan {
      margin-bottom: 20px;
      text-transform: uppercase;
      text-align: center; }
      @media (min-width: 524px) {
        .footer-bottom > .wrapper .footer-slogan {
          transition: all 0.2s ease-in-out;
          margin-bottom: 0;
          text-align: left; } }
    .footer-bottom > .wrapper .footer-copyright {
      margin-bottom: 20px;
      text-align: center; }
      @media (min-width: 524px) {
        .footer-bottom > .wrapper .footer-copyright {
          transition: all 0.2s ease-in-out;
          margin-bottom: 0;
          text-align: left; } }
    .footer-bottom > .wrapper .footer-SMplaces img {
      height: 10px; }
