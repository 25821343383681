.sov {
    font-family: text-family(font-gotham-pro);
    font-size: 14px;
    color: #464646;
    text-transform: uppercase;
    
    margin-top: 20px;
    
    .sov-header {
        font-weight: bold;
    }
    
    .sov-table {
        width: 100%;
        max-width: 450px;
        margin-top: 15px;
        
        .sov-row {
            .sov-cell {
                padding: 8px 8px 8px 0;
                
                .sov-option-name {
                    font-weight: bold;
                }
                
                .sov-option-deadline {
                    font-weight: lighter;
                }
                
                .sov-option-price {
                    color: #777;
                }
            }
        }
    }
    
    .sov-selected-zipcode {
        text-transform: initial;
        font-weight: lighter;
        font-size: 14px;
        color: #777;
        margin-top: 10px;
        
        .sov-marker {
            color: #464646;
            font-size: 18px;
            margin-right: 10px;
        }
    }
}

