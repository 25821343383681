@import "../_config/index";

.footer {
  position: relative;
  background: color(grayPrimary05);
}

.footer-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  position: relative;

  & > .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 2;

    @include breakpoint("ss") {
      flex-direction: row;
    }

    .column {
      margin-bottom: 10px;

      @include breakpoint("ss") {
        margin-bottom: 0px;
      }
    }
  }

  .expandable-menu {
    &.show-only-smartphones {
      @include breakpoint("xs") {
        display: none;
      }
    }

    &.hidden-only-smartphones {
      display: none;
      @include breakpoint("ss") {
        display: block;
      }
    }

    h4 {
      display: block;
      margin-bottom: 5px;
      padding-left: 20px;
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xs);
      font-weight: text-weight(bold);
      color: color(grayPrimary02);
      cursor: pointer;
      position: relative;

      @include breakpoint("ss") {
        cursor: default;
        display: block !important;
      }

      &:before {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        font-family: text-family(font-roboto);
        font-size: 22px;
        font-weight: text-weight(medium);
        color: color(primaryColor);
        transition: all 0.2s ease-in-out;
        position: absolute;
        content: "›";

        @include breakpoint("ss") {
          top: -4px !important;
          transform: rotate(90deg) !important;
        }
      }

      &.expanded-footer {
        &:before {
          top: -4px;
          transform: rotate(90deg);
        }
      }
    }
  }

  .list-links {
    line-height: 24px;
    display: block;
    padding-left: 20px;

    @include breakpoint("ss") {
      line-height: 32px;
      padding-left: 0;
      display: block !important;
    }

    li {
      a {
        display: block;
        font-family: text-family(font-gotham-pro);
        font-size: text-size(xxs);
        font-weight: text-weight(regular);
        text-transform: uppercase;
      }
    }
  }

  .social-links {
    h4 {
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xs);
      font-weight: text-weight(regular);
      text-transform: uppercase;
    }

    ul {
      display: flex;
      padding-left: 0px;

      @include breakpoint("ss") {
        display: block;
        padding-left: 20px;
      }

      li {
        margin-right: 8px;
        margin-bottom: 0px;

        @include breakpoint("ss") {
          margin-right: 0px;
          margin-bottom: 8px;
        }

        a {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          & > span,
          & > i {
            font-size: 26px;
          }
        }
      }
    }
  }

  .footer-logo {
    align-self: center;
    display: none;

    @include breakpoint("ss") {
      display: block;
    }

    img {
      max-width: 300px;
      max-height: 60px;
    }
  }

  .back-to-top {
    margin-bottom: 50px;
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;
    background: color(grayPrimary05);
    order: 1;

    @include breakpoint("ss") {
      display: flex;
    }

    &:after {
      display: block;
      height: 1px;
      background: #dedede;
      top: 50%;
      left: 0;
      right: 0;
      content: "";
      position: absolute;
      z-index: 1;
    }

    .button-go-top {
      width: 180px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: color(greenPrimary);
      border-radius: 4px;
      position: relative;
      border: none;
      z-index: 9;
      transition: transition(fast);

      &:hover {
        padding-bottom: 5px;
      }

      &:before,
      &:after {
        width: 23px;
        background: color(grayPrimary05);
        top: 0;
        bottom: 0;
        display: block;
        content: "";
        position: absolute;
      }

      &:before {
        left: -23px;
      }

      &:after {
        right: -23px;
      }

      &,
      & * {
        text-decoration: none !important;
      }

      & > span,
      & > i {
        color: color(whiteColor);
      }

      & > span {
        font-size: 14px;
        font-weight: medium;
        color: color(whiteColor);
      }

      & > i {
        margin-left: 10px;
      }
    }
  }
}

.footer-bottom {
  background: color(grayPrimary);

  & > .wrapper {
    height: auto;
    display: flex;
    padding: 20px 41px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include breakpoint("ss") {
      height: 62px;
      flex-direction: row;
      padding: 0;
    }

    &,
    & > a {
      font-family: text-family(font-gotham-pro);
      font-weight: text-weight(regular);
      font-size: text-size(xxs);
      color: color(whiteColor);
    }

    .footer-slogan {
      margin-bottom: 20px;
      text-transform: uppercase;
      text-align: center;

      @include breakpoint("ss") {
        margin-bottom: 0;
        text-align: left;
      }
    }

    .footer-copyright {
      margin-bottom: 20px;
      text-align: center;

      @include breakpoint("ss") {
        margin-bottom: 0;
        text-align: left;
      }
    }

    .footer-SMplaces {
      img {
        height: 10px;
      }
    }
  }
}
