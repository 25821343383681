@import "../_config/index";

$menuMobileSize: 300px;

.menu-mobile {
  cursor: pointer;
  display: block;

  @include breakpoint("sm") {
    display: none;
  }

  i {
    height: 54px;
    padding: space(xxs);
    display: flex;
    align-items: center;
    font-size: text-size(l);
    color: color(blackColor);
    order: 0;
  }
}

.categories-menu {
  width: $menuMobileSize;
  display: none;
  background-color: color(whiteColor);
  box-shadow: 0 5px 45px color(grayPrimary);
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 16;

  @include breakpoint("lg") {
    display: none !important;
  }
}

.menu-mobile-html {
  left: $menuMobileSize;

  @include breakpoint("lg") {
    left: 0;
  }
}

.menu-mobile-content {
  & > header {
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: color(redPrimary);

    .close-menu {
      height: 50px;
      background: transparent;
      border: none;
      cursor: pointer;

      i {
        font-size: 24px;
        color: color(whiteColor);
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xs);
      font-weight: text-weight(regular);
      color: color(whiteColor);
      text-decoration: none !important;

      &:hover {
        color: color(whiteColor) !important;
      }

      i {
        margin-right: 10px;
        color: color(whiteColor);
      }
    }

    .user-account {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      i {
        margin-right: 15px;
        font-size: 24px;
        color: color(whiteColor);
      }

      & > div {
        display: flex;
        flex-direction: column;
        line-height: 16px;

        span {
          font-family: text-family(font-gotham-pro);
          font-weight: text-weight(regular);
          font-size: 15px;
          color: color(whiteColor);
        }

        a {
          text-decoration: underline;
          font-family: text-family(font-gotham-pro);
          font-weight: text-weight(regular);
          font-size: text-size(xs);
          color: color(grayPrimary);
        }
      }
    }
  }

  .menu-mobile-main {
    margin: 0;
    padding: 0;

    & > li {
      border-bottom: solid 1px color(grayPrimary05);
    }

    li {
      & a,
      & span {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: text-family(font-gotham-pro);
        font-weight: text-weight(regular);
        font-size: text-size(xs);
        color: color(grayPrimary02);
        transition: transition(fast);
        cursor: pointer;

        &:hover {
          padding-left: 30px;
        }

        i {
          transition: transition(default);
        }

        &.submenu-mobile-active {
          i {
            transform: rotate(90deg);
          }
        }
      }
    }

    & > li {
      &:last-child {
        border-bottom: none;
      }

      ul {
        display: none;
      }

      & > ul {
        & > li {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
