@import "../_config/index";

.slider-home {
  width: 100%;
  position: relative;

  &--desktop {
    display: none;

    @include breakpoint("sm") {
      display: block;
    }
  }

  &--mobile {
    display: block;

    @include breakpoint("sm") {
      display: none;
    }
  }

  .carousel {
    .carousel-indicators {
      width: auto;
      display: block;
      margin: 0;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;

      & > li {
        width: 10px;
        height: 10px;
        margin: 0 12px;
        background: color(whiteColor);
        position: relative;

        &:after {
          width: 0;
          height: 0;
          border: solid 0px color(whiteColor);
          border-radius: 50%;
          content: "";
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          position: absolute;
          transition: transition(fast);
        }

        &.active {
          &:after {
            width: 16px;
            height: 16px;
            border: solid 1px color(whiteColor);
          }
        }
      }
    }

    .carousel-inner {
      .item {
        a {
          img {
            width: 100%;
            height: 300px;
            object-fit: cover;

            @include breakpoint("md") {
              height: 400px;
            }

            @include breakpoint("lg") {
              height: 520px;
            }
          }
        }
      }
    }

    .carousel-control {
      transition: transition(default);
      background-image: none;
      opacity: 1;
      display: none;

      span {
        width: 24px;
        height: 42px;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;

        &:before {
          display: none;
          content: "";
        }
      }
    }
  }
}
