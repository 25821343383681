@import '../_config/index';

.category-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  // @include breakpoint("sm") {
  // }

  // @include breakpoint("lg") {
  // }

  & > div {
    height: 300px;
    display: block;
    overflow: hidden;
    position: relative;

    &:nth-child(2),
    &:nth-child(3) {
      display: none;

      @include breakpoint('sm') {
        display: block;
      }
    }

    @include breakpoint('sm') {
      height: 360px;
    }

    @include breakpoint('lg') {
      height: 460px;
    }

    figure,
    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }

    h2 {
      margin: 0;
      padding: 15px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(#7c7c7c, 0.9);
      border: 1px solid #9e9e9e;
      top: 50%;
      left: 50%;
      border-radius: 8px;
      transform: translateY(-50%) translateX(-50%);
      text-transform: uppercase;
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xxl);
      font-weight: text-weight(medium);
      color: color(whiteColor);
      position: absolute;
    }
  }

  & > .banner-1 {
    width: 100%;
    order: 1;
  }

  & > .banner-2 {
    width: 50%;
    order: 2;
  }

  & > .banner-3 {
    width: 50%;
    order: 3;
  }
}

.shop-area {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  @include breakpoint('sm') {
    flex-direction: row;
  }

  .shop-area-sidebar {
    width: $sidebarWidth;
    display: none;
    background-color: color(whiteColor);
    box-shadow: 0 5px 45px color(grayPrimary);
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 16;

    @include breakpoint('md') {
      width: $sidebarWidth;
      display: block !important;
      background-color: transparent;
      box-shadow: initial;
      overflow-y: initial;
      overflow-x: initial;
      top: initial;
      bottom: initial;
      left: initial;
      position: initial;
      z-index: initial;
    }
  }

  .shop-area-content {
    display: flex;
    flex-direction: column;
    margin-left: 0px;

    @include breakpoint('md') {
      width: calc(100% - #{$sidebarWidth} - 20px);
      margin-left: 20px;
    }
  }
}

.shop-area-list {
  margin-bottom: 0px;

  @include breakpoint('md') {
    display: none;
  }

  .grid-banners {
    margin-bottom: 30px;
  }

  &-mobile {
    display: flex;
    margin-bottom: 40px;
    position: relative;

    @include breakpoint('md') {
      display: none;
    }

    .grid-banners {
      margin-bottom: 0 !important;
    }

    .owl-item,
    .owl-stage,
    .product-box-container {
      height: 100%;
    }

    .owl-nav {
      left: -10px;
      right: -10px;
      transform: translateY(calc(-50% - 40px));

      button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color(redPrimary) !important;

        span {
          display: inline-block;
          line-height: 20px;
          font-size: 24px;
          color: #fff;
        }
      }
    }
  }

  &-desktop {
    display: none;

    & > div {
      transition: transition(fast);
    }

    @include breakpoint('md') {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }

    & > .product-box-container {
      width: calc((100% - 15px) / 2);

      @include breakpoint('lg') {
        width: calc((100% - (15px * 2)) / 3);
      }

      .product-box {
        width: 100%;
        height: 100%;
      }
    }

    & > .fx {
      display: flex;
      margin: 0;
      padding: 0;
      float: none;
      flex: initial;

      .product-box {
        width: 100%;
      }
    }

    & > .col-xs-3 {
      width: calc((100% - (15px * 3)) / 4);
    }

    & > .col-xs-4 {
      width: calc((100% - (15px * 2)) / 3);
    }

    & > .col-xs-6 {
      width: calc((100% - 15px) / 2);
    }
  }
}

.grid-banners {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  & > a,
  & > a > img {
    width: 100%;
    display: block;
  }

  &.banner-category-1 {
    grid-area: banner-category-1;
  }

  &.banner-category-2 {
    grid-area: banner-category-2;
  }

  &.banner-category-3 {
    grid-area: banner-category-3;
  }
}

.shop-area-sidebar {
  .shop-sidebar-header {
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: color(redPrimary);

    .close-menu {
      height: 50px;
      background: transparent;
      border: none;
      cursor: pointer;

      i {
        font-size: 22px;
        color: color(whiteColor);
      }
    }

    h3 {
      padding: 0;
      margin: 0;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: text-weight(bold);
      color: color(whiteColor);

      i {
        margin-right: 10px;
        font-size: 26px;
      }
    }

    @include breakpoint('md') {
      display: none;
    }
  }

  .shop-sidebar-content {
    margin-bottom: 30px;
    padding: 20px;

    @include breakpoint('md') {
      padding: 0px;
    }

    & > .filter {
      padding: 0;
      margin: 0 0 25px;
      border-bottom: solid 1px #f7f7f7;

      .filter-option {
        box-shadow: none;

        & > span {
          height: auto;
          margin: 0 0 25px;
          padding: 0;
          box-shadow: none;
          border-bottom: none;
          display: flex;
          align-items: center;
          font-family: text-family(font-gotham-pro);
          font-size: text-size(xxs);
          font-weight: text-weight(bold);
          color: color(grayPrimary02);

          &:after {
            display: none;
          }
        }

        .filter-values {
          max-height: 255px;
          border: none;
          padding: 0;
          margin: 0 0 25px;
          overflow-y: visible;

          &.filter-size {
            li {
              margin: 3px 1px;
              padding: 0;

              a {
                border-radius: 3px;
                border: 1px solid color(grayPrimary03);
                font-family: text-family(font-gotham-pro);
                font-size: text-size(xxs);
                font-weight: text-weight(light);
                color: color(grayPrimary03);
              }
            }
          }

          & > .filter-value {
            & > a {
              display: flex;
              align-items: center;
              font-family: text-family(font-gotham-pro);
              font-size: text-size(xs);
              font-weight: text-weight(light);
              color: color(grayPrimary03);

              &:before {
                width: 14px;
                height: 14px;
                display: flex;
                margin: 0;
                padding: 0;
                top: 50%;
                left: 0;
                font-family: FontAwesome !important;
                font-size: 14px;
                align-items: center;
                justify-content: center;
                vertical-align: inherit;
                background-clip: inherit;
                transform: translateY(-50%);
                transition: transition(fast);
                opacity: 0;
                content: '\f00c';
                position: absolute;
              }

              &:after {
                width: 14px;
                height: 14px;
                border-radius: 3px;
                border: solid 1px color(grayPrimary02);
                order: -1;
                margin-right: 8px;
                content: '';
              }
            }

            &--selected > a,
            & > a:hover {
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.filter-clear-all {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color(whiteColor);
  border: solid 1px color(greenPrimary);
  border-radius: 4px;
  text-decoration: none !important;
  font-family: text-family(font-gotham-pro);
  font-size: text-size(xs);
  font-weight: text-weight(medium);
  color: color(greenPrimary);
  transition: transition(fast);

  &:hover {
    background-color: color(greenPrimary);
    color: color(whiteColor) !important;
  }
}

.list-banners-sidebar {
  display: none;

  @include breakpoint('md') {
    display: block;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.button-filter-mobile {
  width: 100%;
  height: 42px;
  padding: 0 16px;
  background: color(grayPrimary05);
  display: flex;
  border: none;
  border-radius: 6px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  font-family: text-family(font-gotham-pro);
  font-weight: text-weight(regular);
  font-size: text-size(xxs);
  color: color(grayPrimary03);
  cursor: pointer;

  @include breakpoint('md') {
    display: none;
  }

  &:after {
    display: block;
    font-family: 'FontAwesome';
    font-size: 22px;
    content: '\f107';
    color: color(redPrimary);
  }
}



.all-categories__container {
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: #464646;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::before{
      display: none;
    }
  }
  &--main-categories {
    .all-categories__first-level {
      font-size: 20px;
      font-weight: bold;
      color: #464646;
      line-height: 23px;
      padding-bottom: 30px;
      padding-top: 72px;
      text-transform: uppercase;
      display: flex;
      &:hover {
        text-decoration: none;
      }
    }

    .all-categories__second-level {
      font-size: 14px;
      font-weight: 500;
      color: #6f6f6f;
      line-height: 16px;
      padding-bottom: 12px;
      &:has(~ .all-categories__third-level) {
        color: #6f6f6f;
        font-weight: bold;
        line-height: 18px;
        padding-bottom: 12px;
        font-size: 16px;
      }
    }
    .all-categories__third-level {
      font-size: 14px;
      font-weight: 500;
      color: #6f6f6f;
      line-height: 16px;
      padding-bottom: 12px;
    }

    .u-push-top-2 {
      margin: 0;
    }
  }
}
