@import "../_config/index";

.logo {
  &,
  & h1 {
    margin: 0;
  }

  img {
    max-width: 240px;
    max-height: 34px;
    display: block;

    @include breakpoint("sm") {
      max-height: 42px;
    }

    @include breakpoint("lg") {
      max-height: 50px;
    }
  }
}
