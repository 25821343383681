@import "../_config/index";

.menu {
  &-mobile {
    display: block;

    @include breakpoint("lg") {
      display: none;
    }
  }

  & > ul {
    display: none;

    @include breakpoint("lg") {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    & > li {
      margin: 0 30px;
      display: block;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.menu-relative {
        position: relative;
      }

      a {
        font-family: text-family(font-gotham-pro);
        font-weight: text-weight(regular);
        color: color(grayPrimary);
      }

      & > a {
        height: 34px;
        display: flex;
        align-items: center;
        text-decoration: none !important;
        text-transform: uppercase;
        font-size: text-size(xs);
        font-weight: text-weight(bold);
        position: relative;
      }

      &:hover {
        & > a {
          color: color(redPrimary);
          text-decoration: none;
        }

        .submenu {
          top: 100%;
          display: flex;
          flex-direction: row;
        }
      }

      &.li-megamenu {
        position: relative;

        & > a {
          color: color(grayPrimary03);
        }

        &:hover {
          & > a {
            color: color(redPrimary);
          }
        }

        & > .megamenu {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &.menu-relative {
        .submenu {
          min-width: 430px;
        }

        &:nth-last-child(-n + 2),
        &:nth-last-child(-n + 3),
        &:last-child {
          .submenu {
            left: auto !important;
            right: -20px !important;
            transform: translateX(0%);
          }
        }
      }
    }
  }


  .submenu {
    display: none;
    padding: 0;
    margin: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    overflow: hidden;
    overflow-x: auto;
    background: color(whiteColor);
    box-shadow: 0 2px 10px rgba(color(blackColor), 0.29);
    transition: transition(default);
    position: absolute;
    z-index: 9;
    

    .menu-category-image {
      width: 230px;
      height: 260px;
      padding: 0;
      margin: 0;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .submenu-columns {
      max-width: 550px;
      padding: 9px 20px;
      display: flex;
      flex-direction: row;
      overflow-y: hidden;
      overflow-x: auto;

      & > ul {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin-right: 40px;
        }

        & > li {
          a {
            height: 26px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: text-weight(light);
            white-space: nowrap;
            color: color(grayPrimary03);

            strong {
              font-weight: text-weight(bold);
            }

            &:hover {
              span {
                &:after {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .megamenu {
    width: auto;
    min-width: auto;
    padding: 5px 0;
    border-radius: 3px;
    right: initial;
    overflow: visible;

    & > ul {
      & > li {
        &:not(:last-child) {
          border-bottom: solid 1px rgba(color(grayPrimary03), 0.1);
        }

        & > a {
          min-width: 230px;
          height: 34px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: text-weight(regular);
          text-decoration: none !important;
          font-size: 14px;
          color: color(grayPrimary03);
        }

        & > .submenu-internal {
          max-width: 360px;
          height: 100%;
          padding: 7px 20px;
          display: none;
          left: 100%;
          top: 0;
          background: color(whiteColor);
          flex-direction: row;
          justify-content: space-between;
          box-shadow: 0 2px 10px rgba(color(blackColor), 0.29);
          overflow-y: hidden;
          overflow-x: auto;
          position: absolute;

          @include breakpoint("md") {
            max-width: 520px;
          }

          @include breakpoint("lg") {
            max-width: 740px;
          }

          ul {
            &:not(:last-child) {
              padding-right: 20px;
            }

            li {
              a {
                height: 32px;
                display: flex;
                align-items: center;
                font-weight: text-weight(light);
                white-space: nowrap;
                text-decoration: none;

                &,
                strong {
                  font-family: text-family(font-gotham-pro);
                  color: color(grayPrimary);
                }

                strong {
                  font-weight: text-weight(bold);
                }

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        &:hover {
          & > a {
            padding-left: 25px;
            text-decoration: none !important;
            color: color(redPrimary) !important;
          }

          .submenu-internal {
            display: flex;
          }
        }
      }
    }
  }
}
