@import "../_config/index";

@keyframes ofsv-mask-transition {
    from {opacity: 0;}
    to {opacity: 0.5;}
}

.ofsv-mask-transition-enter {
    animation: ofsv-mask-transition 0.7s;
}

.ofsv-mask-transition-leave {
    animation: ofsv-mask-transition 0.7s reverse;
}

@keyframes ofsv-side-panel-transition {
    from {right: -630px;}
    to {right: 0;}
}

.ofsv-side-panel-transition-enter {
    animation: ofsv-side-panel-transition 0.7s;
}

.ofsv-side-panel-transition-leave {
    animation: ofsv-side-panel-transition 0.7s reverse;
}

.ofsv-mask-panel {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 10;
}

.ofsv-close-btn-panel {
    position: fixed;
    top: 25px;
    right: 640px;
    z-index: 11;
    
    .ofsv-close-button {
        color: white;
        font-size: 25px;
        cursor: pointer;
    }
}

.ofsv-side-panel {
    display: flex;
    flex-direction: column;
    z-index: 11;
    background-color: white;
    position: fixed;
    right: 0;
    top: 0;
    width: 630px;
    height: 100%;
    padding: 30px 20px 30px 30px;
    font-family: text-family(font-gotham-pro);
    
    .ofsv-title {
        font-family: text-family(font-gotham-pro);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    .ofsv-offers-scroll{
        overflow-y: auto;
        flex-grow: 1;
        // Para melhor acomodação da scroll bar
        padding-right: 10px;
        
        .ofsv-offer-panel{
            display: flex;
            border-bottom: solid 1px #EEE;
            margin-bottom: 15px;
            padding-bottom: 15px;
            
            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }
            
            .ofsv-offer-img{
                width: 80px;
                height: 80px;
            }
            
            .ofsv-offer-data {
                flex-direction: column;
                flex-grow: 1;
                padding: 10px 0 10px 20px;
                
                .ofsv-offer-label {
                    font-weight: bold;
                    margin-right: 5px;
                }
                .ofsv-offer-value {
                    color: #777;
                }
                .ofsv-offer-data-first-row {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    flex-grow: 1;
                    margin-bottom: 15px;
                    
                    .ofsv-offer-store {
                    }
                    
                    .ofsv-offer-button {
                        .ofsv-offer-buybtn{
                            height: 28px;
                            line-height: 26px;
                            padding: 1px 12px 2px 12px;
                            text-transform: uppercase;
                            font-size: 12px;
                            color: white;
                            background-color: #1fba9b;
                        }
                    }
                
                }
                .ofsv-offer-data-second-row {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    align-items: center;
                    
                    .ofsv-offer-price {
                        font-weight: text-weight(bold);
                        color: color(redPrimary);
                        font-size: 17px;
                    }
                    .ofsv-offer-shipping {
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 680px) {
    .ofsv-side-panel {
        width: 100%;
        top: 90px;
        .ofsv-offers-scroll{
            .ofsv-offer-panel{
                .ofsv-offer-img{
                    width: 60px;
                    height: 60px;
                }
                
                .ofsv-offer-data {
                    padding-top: 0;
                    
                    .ofsv-offer-data-first-row {
                        flex-direction: column-reverse;
                        align-items: flex-start;
                        margin-bottom: unset;
                        
                        .ofsv-offer-store {
                            margin-top: 10px;
                        }
                    }
                
                    .ofsv-offer-data-second-row {
                        flex-direction: column-reverse;
                        align-items: flex-start;
                    }
                }
            }
        }
    }

    .ofsv-close-btn-panel {
        top: 60px;
        left: 25px;
        right: unset;
    }
    
    @keyframes ofsv-side-panel-transition {
        from {top: 100vh;}
        to {top: 90px;}
    }
}
