@import "../_config/index";

.showcases {
  .shop-area-home {
    display: none;

    @include breakpoint("sm") {
      display: grid;
    }
  }

  &:not(:last-child) {
    margin-bottom: 30px;

    @include breakpoint("lg") {
      margin-bottom: 60px;
    }
  }

  &-mobile {
    display: block;
    position: relative;

    @include breakpoint("sm") {
      display: none;
    }

    .owl-nav {
      left: -10px;
      right: -10px;
      transform: translateY(calc(-50% - 50px));

      button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color(redPrimary) !important;

        span {
          display: inline-block;
          line-height: 20px;
          font-size: 24px;
          color: #fff;
        }
      }

      @include breakpoint("sm") {
        left: -16px;
        right: -16px;
      }
    }
  }
}

.shop-area-content {
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 15px 15px;
  grid-template-areas:
    ". . . ."
    ". . . .";
}
