@import "../_config/index";

.page-institutional {
  margin-top: 10px;
  margin-bottom: 20px;

  @include breakpoint("sm") {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  @include breakpoint("lg") {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
