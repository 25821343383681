@import "../_config/index";

.form-search {
  width: auto;
  display: flex;
  flex-grow: 1;
  position: relative;

  @include breakpoint("sm") {
    flex-grow: 0.5;
  }

  .input-search {
    width: 100%;
    height: 42px;
    padding: 0 20px 0 52px;
    border: solid 1px color(#52606c);
    border-radius: 3px;
    text-align: center;
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xxs);
    font-weight: text-weight(regular);
    color: color(grayPrimary);

    @include breakpoint("lg") {
      height: 32px;
    }

    @include placeholder {
      color: color(grayPrimary03);
    }
  }

  .button-search {
    width: 38px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding: 0;
    margin: 0;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border: none;
    position: absolute;

    img {
      margin: 0;
      padding: 0;
    }

    @include breakpoint("lg") {
      left: 0px;
    }
  }
}
