@import "../_config/index";

@keyframes loading-component {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.variants-loaded {
  background-image: linear-gradient(
    90deg,
    #ededed,
    #ccc,
    #ededed,
    #ccc
  ) !important;
  background-size: 300% 100% !important;
  animation: loading-component 1s linear infinite !important;
  opacity: 0.3;
}

.product-content {
  display: flex;
  flex-direction: column;

  @include breakpoint("sm") {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
  }
}

.product-info {
  width: 100%;
  margin-bottom: 40px;

  @include breakpoint("sm") {
    width: calc(40% - 20px);
  }

  @include breakpoint("lg") {
    width: calc(100% - #{$gallerySize} - 20px);
    margin-bottom: 0px;
  }
}

.product-title {
  padding: 0;
  margin: 0;
  line-height: 32px;
  font-family: text-family(font-gotham-pro);
  font-size: text-size(l);
  font-weight: text-weight(bold);
  color: color(grayPrimary02);
  text-transform: uppercase;
}

.title-with-shared {
  .product-title {
    display: inline;
  }

  .product-share {
    top: -3px;
    display: inline-block;

    .product-share-list {
      right: -8px;
    }
  }
}

.product-sku {
  display: block;
  margin-bottom: 24px;
  font-family: text-family(font-gotham-pro);
  font-size: text-size(xs);
  font-weight: text-weight(light);
  color: color(grayPrimary03);
}

.variants-thumbnail {
  margin-bottom: 40px;

  h4 {
    margin-bottom: 20px;
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xs);
    font-weight: text-weight(medium);
    color: color(grayPrimary02);
  }

  &-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 24px;

    &:hover {
      li {
        opacity: 0.6;
      }
    }

    li {
      width: 90px;
      height: 90px;
      border-radius: 4px;
      overflow: hidden;
      transition: transition(default);
      position: relative;

      &:hover {
        opacity: 1;

        label {
          img {
            transform: scale(1);
          }
        }
      }

      input[type="radio"] {
        display: none;

        &:checked + label {
          span {
            background-color: color(redPrimary);
          }
        }
      }

      label {
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
        position: relative;

        span {
          padding: 5px 7px;
          background-color: rgba(#9e9e9e, 0.2);
          border: solid 1px #b7b7b7;
          border-radius: 3px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          text-transform: uppercase;
          font-family: text-family(font-gotham-pro);
          font-size: 8px;
          font-weight: text-weight(medium);
          color: color(whiteColor);
          position: absolute;
          backdrop-filter: blur(2px);
          transition: transition(default);
          z-index: 2;
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: left center;
          transition: transition(default);
          transform: scale(1.4);
          z-index: 1;
        }
      }
    }
  }
}

.product-price {
  margin-bottom: 30px;

  del {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xs);
    font-weight: text-weight(regular);
    color: color(grayPrimary02);
  }

  &-in-cash {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    .product-price-avista{
        display: flex;
        margin-bottom: 5px;
    }

    .product-price-installments-amount{
        font-weight: text-weight(bold);
    }

    .product-price-installments-value{
        font-weight: text-weight(bold);
    }

    h3 {
      margin: 0;
      font-family: text-family(font-gotham-pro);
      font-size: 22px;
      font-weight: text-weight(bold);
      color: color(redPrimary);
    }

    span {
      align-self: flex-end;
      font-family: text-family(font-gotham-pro);
      font-size: 16px;
      font-weight: text-weight(light);
      color: color(grayPrimary03);
    }
  }

  &-divided {
    font-family: text-family(font-gotham-pro);
    font-size: 16px;
    font-weight: text-weight(light);
    color: color(grayPrimary03);

    strong {
      font-weight: text-weight(medium);
    }
  }

  &-discount {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &-info {
      background: #cc1d21;
    }

    &-percentage {
      padding: 5px 7px;
      margin-right: 30px;
      border-radius: 3px;
      background: color(redPrimary);
      text-transform: uppercase;
      font-family: text-family(font-gotham-pro);
      font-size: 10px;
      line-height: 20px;
      font-weight: bold;
      color: color(whiteColor);
    }
  }
}

.product-add-to-cart-button {
  margin-bottom: 25px;
  width: 240px;
  height: 40px;
  text-transform: uppercase;
  font-family: text-family(font-gotham-pro);
  font-size: 12px;
}

.product-sold-delivered {
  font-family: text-family(font-gotham-pro);
  font-size: text-size(xxs);
  font-weight: text-weight(light);
  color: color(grayPrimary02);
  text-transform: uppercase;

  a,
  span {
    text-transform: uppercase;
    font-size: text-size(m);
    font-weight: text-weight(bold);
    color: color(redPrimary);
  }
}

.product-more-offers {
    margin-top: 20px;
    background-color: white;
    border: solid 1px #444;
    text-transform: uppercase;
    font-weight: bold;
    height: 40px;
    line-height: 28px;
}

.product-description {
  margin-bottom: 60px;

  &-text {
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xs);
    font-weight: text-weight(light);
    color: color(grayPrimary03);
  }
}

.showcases-content {
  padding: 0 60px;
  position: relative;
  margin-bottom: 60px;
}

.product-share {
  display: block;
  position: relative;
  margin-right: 10px;

  &-button {
    margin-left: 5px;
    transform: translateY(2px);
    text-decoration: none !important;
    background: none;
    display: block;
    border: none;

    i {
      display: block;
      font-size: text-size(m);
      color: color(grayPrimary03);
      font-weight: text-weight(light);
    }
  }

  &-list {
    right: 0;
    bottom: 100%;
    position: absolute;
    z-index: 9;
    display: none;

    &:before,
    &:after {
      background-color: transparent;
      display: block;
      content: "";
    }

    &:before {
      width: 0;
      height: 0;
      border-top: solid 15px color(whiteColor);
      border-right: solid 8px transparent;
      border-bottom: solid 0px transparent;
      border-left: solid 8px transparent;
      bottom: 0px;
      right: 10px;
      position: absolute;
    }

    &:after {
      width: 100%;
      height: 15px;
    }

    & > ul {
      display: flex;
      column-gap: 15px;
      padding: 15px 20px;
      background: color(whiteColor);
      border-radius: 4px;
      box-shadow: 0px 6px 8px rgba(color(blackColor), 0.2);

      li {
        a {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none !important;

          i {
            font-size: 20px;
            color: color(grayPrimary02);
          }

          &:hover {
            transform: translateY(-5px);
          }
        }

        &.product-share-list-facebook > a {
          background: color(grayPrimary02);
          border-radius: 50%;

          i {
            color: color(whiteColor);
            font-size: 16px;
          }
        }
      }
    }
  }

  &:hover {
    .product-share-list {
      display: block;
    }
  }
}

.product-questions {
  margin-bottom: 60px;
}

.product-question-form {
  & > div {
    $buttonSize: 200px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    [type="text"] {
      width: calc(100% - 20px - #{$buttonSize});
      height: 42px;
      padding: 0 20px;
      display: block;
      border: solid 1px color(grayPrimary03);
      border-radius: 4px;
      background: color(whiteColor);
      margin-right: 20px;
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xxs);
      font-weight: text-weight(regular);
      color: color(grayPrimary03);

      &[disabled="disabled"] {
        cursor: not-allowed;
        background-color: color(grayPrimary04);
        color: color(grayPrimary02);
      }
    }

    [type="submit"] {
      width: $buttonSize;
      height: 42px;
      display: block;
      border: solid 1px color(greenPrimary);
      border-radius: 4px;
      background: color(whiteColor);
      text-transform: uppercase;
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xxs);
      font-weight: text-weight(medium);
      color: color(greenPrimary);
      transition: transition(fast);
      cursor: pointer;

      &:hover {
        background: color(greenPrimary);
        color: color(whiteColor);
      }

      &[disabled="disabled"] {
        &,
        &:hover {
          cursor: not-allowed;
          border: solid 1px color(grayPrimary03);
          background-color: color(grayPrimary04);
          color: color(grayPrimary03);
        }
      }
    }
  }

  & > p {
    margin: 0;
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xxs);
    font-weight: text-weight(regular);
    color: color(grayPrimary02);

    a {
      color: color(grayPrimary03);
    }
  }
}

.product-questions-list {
  margin-top: 40px;
}
