@import "../_config/index";

.top-strip {
  background: color(grayPrimary);

  &-wrapper {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-links {
    display: flex;

    li {
      margin: 0 15px;
      display: none;

      &:nth-child(1) {
        margin-left: 0;
        display: block;
      }

      &:nth-child(2) {
        @include breakpoint("xs") {
          display: block;
        }
      }

      &:nth-child(3) {
        @include breakpoint("ss") {
          display: block;
        }
      }

      &:nth-child(4) {
        @include breakpoint("sm") {
          display: block;
          margin-right: 0;
        }
      }

      a {
        height: 32px;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        text-decoration: none;
        text-transform: uppercase;
        font-family: text-family(font-gotham-pro);
        font-weight: text-weight(light);
        font-size: 12px;

        @include breakpoint("sm") {
          font-size: text-size(xxs);
          font-weight: text-weight(regular);
        }
      }
    }
  }

  .form-search {
    background: transparent;
    display: none;

    @include breakpoint("lg") {
      display: flex;
    }
  }

  .user-links {
    justify-content: space-between;

    &,
    & > a,
    & > div > a {
      display: flex;
      align-items: center;
      text-decoration: none !important;
    }
  }

  .user-nav-login {
    position: relative;

    &:hover {
      .menu-user-dropdown {
        display: flex;
      }
    }

    a {
      height: 32px;
      font-size: 12px;
      font-weight: text-weight(light);

      @include breakpoint("sm") {
        font-size: text-size(xxs);
        font-weight: text-weight(regular);
      }

      span {
        height: 14px;
        margin-right: space(xs);

        &.user-login-text {
          margin-right: space(s);
          text-transform: uppercase;
        }

        @include breakpoint("sm") {
          height: 19px;
        }
      }

      img {
        max-width: 18px;
        max-height: 18px;

        @include breakpoint("sm") {
          max-width: 25px;
          max-height: 25px;
        }
      }
    }

    .menu-user-dropdown {
      width: 170px;
      top: 100%;
      left: 50%;
      display: none;
      flex-direction: column;
      padding: 16px;
      border-radius: 4px;
      transform: translateX(-50%);
      background-color: #fff;
      box-shadow: 0 3px 6px rgba(color(blackColor), 0.29);
      position: absolute;
      z-index: 9;

      &:before {
        top: -8px;
        right: 50px;
        border-right: solid 4px transparent;
        border-bottom: solid 8px color(whiteColor);
        border-left: solid 4px transparent;
        display: block;
        content: "";
        position: absolute;
      }

      p {
        margin-bottom: 12px;
        line-height: 13px;
        font-size: 12px;
        font-weight: text-weight(light);
        color: color(grayPrimary03);
      }

      .button {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        text-decoration: none !important;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &.contained {
          background-color: color(greenPrimary);
          color: color(whiteColor);

          &:hover {
            background-color: darken(color(greenPrimary), 15%);
          }
        }

        &.outlined {
          border: solid 1px color(grayPrimary03);
          background: transparent;
          color: color(grayPrimary03);

          &:hover {
            background: color(grayPrimary03);
            color: color(whiteColor);
          }
        }
      }
    }
  }
  
  .user-cart {
    margin-left: 20px;
    margin-right: 10px;
    
    @include breakpoint("sm") {
      margin-left: 40px;
      margin-right: 0px;
    }
    
    img {
      max-width: 18px;
      max-height: 18px;

      @include breakpoint("sm") {
        max-width: 25px;
        max-height: 25px;
      }
    }    
  }
}
