@import "../_config/index";

.wrapper-newsletter {
  background: color(grayPrimary05);
  padding-bottom: 50px;
}

.message-newsletter {
  min-width: 700px;
  padding: 20px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: color(greenPrimary);
  border-radius: 5px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;

  & > i {
    font-size: 22px;
    font-weight: 100;
    color: color(whiteColor);
  }

  p {
    margin: 0;
    padding: 0;
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xxs);
    font-weight: text-weight(regular);
    color: color(whiteColor);
  }

  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    background: transparent;
    justify-content: center;
    border: none;

    i {
      font-size: 20px;
      font-weight: 100;
      color: color(whiteColor);
    }
  }
}

.newsletter {
  #formulario-site-01844c5231f3f4712ee2 {
    width: 100%;
  }
  div[id^="rdstation-bricks-embeddable-form-bricks-component-"] {
    .bricks--component-embeddable-form {
      border: 0 !important;
      max-width: 1366px;
      width: 100% !important;
    }
    .bricks--row{
      width: 100%;
    }
    div[id^="rd-column-"] > div {
      background: color(grayPrimary) !important;
    }
    .rd-section{
      .rd-row{
        width: 100% !important;
        max-width: 100% !important;
        display: flex;
        flex-direction: column !important;
        @include breakpoint("md") {
          flex-direction: row !important;
        }
        .rd-column{
          div{
            .rd-text{
              max-width: 410px !important;
              h4 {
                strong {
                  color: rgb(255, 255, 255);
                  font-size: 32px !important;
                  font-weight: 600 !important;
                  text-align: left;
                  letter-spacing: normal;
                  font-family: "Gotham Pro", sans-serif  !important;
                }
              }
              p {
                span {
                  font-size: 20px !important;
                  font-weight: 300;
                  color: #fff;
                }
              }
            }
            .rd-form {
              #rd-button-l8d17yb6 {
                width: 100%;
                height: 42px;
                border: none;
                border-radius: 4px;
                transition: transition(fast);
                font-family: text-family(font-gotham-pro);
                font-size: text-size(xs);
                font-weight: text-weight(bold);
                background: color(greenPrimary);
                color: lighten(color(whiteColor), 20%);
                max-width: 410px !important;
              }
              #rd-checkbox_field-l8dcc3v2 {
                font-family: "Gotham Pro", sans-serif;
                font-size: 14px;
                font-weight: 100;
                color: #fff;
              }
              #rd-email_field-_ohmLV7ysXfvlWzCXFDwjw,
              #rd-select_field-TyOatV0AvtmSvIC_fa1dAg {
                width: 100%;
                height: 42px;
                border: none;
                border-radius: 4px;
                margin-right: 0px;
                background: #fff;
                padding: 0 16px;
                font-family: "Gotham Pro", sans-serif;
                font-size: 16px;
                font-weight: 300;
                color: #7c7c7c;
              }
              .bricks-form__label {
                font-family: "Gotham Pro", sans-serif;
                font-size: 14px  !important;
                font-weight: 100  !important;
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }

  background: color(grayPrimary);
  padding: 45px 0;
  position: relative;
  margin-top: 51px;

  & > .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    @include breakpoint("md") {
      flex-direction: row;
    }
  }

  .footer-newsletter__button {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 4px;
    transition: transition(fast);
    background: color(greenPrimary);
    font-family: text-family(font-gotham-pro);
    font-size: text-size(xs);
    font-weight: text-weight(bold);
    color: color(whiteColor);

    &[disabled],
    &[disabled="disabled"] {
      &,
      &:hover {
        cursor: not-allowed;
        background: color(grayPrimary03);
        color: lighten(color(grayPrimary03), 20%);
      }
    }

    &:hover {
      background: color(greenPrimary);
    }
  }

  .newsletter-description,
  .newsletter-form {
    max-width: 410px;
    margin: 0 auto;
  }

  .newsletter-description {
    margin-bottom: 30px;

    @include breakpoint("md") {
      margin-bottom: 0;
    }

    h4,
    h5 {
      font-family: text-family(font-gotham-pro);
      color: color(whiteColor);
      text-align: center;

      @include breakpoint("md") {
        text-align: left;
      }
    }

    h4 {
      font-size: text-size(l);
      font-weight: text-weight(bold);

      @include breakpoint("md") {
        font-size: text-size(xxl);
      }
    }

    h5 {
      font-size: text-size(xs);
      font-weight: text-weight(regular);

      @include breakpoint("md") {
        font-size: text-size(s);
      }
    }
  }

  .newsletter-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    fieldset {
      padding: 0;
      margin: 0;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      input {
        width: 100%;
        height: 42px;
        border: none;
        border-radius: 4px;
        margin-right: 0;
        margin-bottom: 10px;
        background: color(whiteColor);
        padding: 0 16px;
        font-family: text-family(font-gotham-pro);
        font-size: text-size(xs);
        font-weight: text-weight(regular);
        color: color(grayPrimary03);
      }

      label {
        margin-bottom: 20px;

        @include breakpoint("md") {
          margin-bottom: 0px;
        }
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 20px;

      @include breakpoint("md") {
        margin-bottom: 0px;
      }

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin: 0 10px;
        margin-left: 0px;
        margin-right: 10px;
        position: relative;
      }

      span {
        font-family: text-family(font-gotham-pro);
        font-size: text-size(xxs);
        font-weight: text-weight(light);
        color: color(whiteColor);
      }
    }

    & > .footer-newsletter__button {
      width: 100%;
      display: block;
    }
  }
}
