@import "../_config/index";

.product-box {
  border-radius: 6px;
  background: #fff;
  border: solid 1px #f7f7f7;
  overflow: hidden;

  .product-box-image {
    width: 100%;
    height: 210px;
    display: block;
    background: #f7f7f7;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      background: #f7f7f7;
      align-items: center;
      justify-content: center;

      img {
        max-width: 229px;
        max-height: 210px;
        display: block;
        transition: transition(default);
        @include breakpoint("lg") {
          max-width: 330px;
        }
      }
    }
  }

  .discount-percentage {
    padding: 3px 6px;
    display: block;
    border-radius: 2px;
    overflow: hidden;
    top: 15px;
    right: 15px;
    background: color(redPrimary);
    font-family: text-family(font-gotham-pro);
    font-size: 8px;
    font-weight: text-weight(light);
    color: color(whiteColor);
    position: absolute;
  }

  .product-box-code {
    margin: 0 0 5px;

    &,
    a {
      display: block;
      font-family: text-family(font-gotham-pro);
      font-size: 20px;
      font-weight: text-weight(bold);
      color: #272626;
    }
  }

  .product-box-title {
    margin: 0 0 5px;

    &,
    a {
      display: block;
      font-family: text-family(font-gotham-pro);
      font-size: 12px;
      font-weight: text-weight(light);
      color: #7c7c7c;
    }
  }

  .product-content {
    background: #fff;
    padding: 14px 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;

    .product-prices {
      padding-top: 10px;
      display: flex;
      align-items: center;

      del,
      h3 {
        margin: 0;
      }

      del {
        margin-right: 10px;
        font-family: text-family(font-gotham-pro);
        font-size: 10px;
        font-weight: text-weight(light);
        color: #7c7c7c;
      }

      h3 {
        font-family: text-family(font-gotham-pro);
        font-size: 14px;
        font-weight: text-weight(bold);
        color: #464646;
      }
    }

    .product-share {
      bottom: 11px;
      right: 6px;
      position: absolute;
    }
  }

  &:hover {
    .product-box-image {
      img {
        transform: scale(1.1);
      }
    }
  }
}
