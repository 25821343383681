@import "../_config/index";

.mobile-carousel-wrapper {
    @include breakpoint("sm") {
        display: block;
    }

    @include breakpoint("lg") {
      display: none;
    }
}

.gallery {
  display: none;

  @include breakpoint("md") {
    width: $gallerySize;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-columns: 1fr;
    gap: 15px 15px;
    grid-auto-flow: row;
    grid-template-areas:
      "image-0 image-0"
      "image-1 image-2"
      "image-3 image-3"
      "image-4 image-5";
  }

  & > li {
    height: 400px;
    background: color(grayPrimary05);
    overflow: hidden;
    border-radius: 8px;
    position: relative;

    figure {
      position: relative;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.zoomImg {
        background-color: #f7f7f7;
      }

      &.product-image-main {
        &:hover {
          cursor: zoom-in;
        }
      }
    }

  }

  &-mobile {
    width: 100%;
    height: 460px;
    margin-bottom: 40px;
    display: block;
    position: relative;
    background: #f7f7f7;
    border-radius: 6px;
    position: relative;

    .active-mouse-hover {
      content: "";
      background: transparent;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      position: absolute;
      z-index: 9;
    }

    @include breakpoint("sm") {
      width: 60%;
      margin-right: 20px;
    }

    @include breakpoint("md") {
      display: none;
    }

    li {
      overflow: hidden;
      &,
      figure,
      figure a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 460px;
        }
      }
      .loading-video {
        img {
          height: 100%;
        }
      }
    }

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
      overflow: hidden;
    }

    .owl-item {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .owl-nav {
      left: -12px;
      right: -12px;

      button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color(redPrimary) !important;

        span {
          display: inline-block;
          line-height: 20px;
          font-size: 24px;
          color: #fff;
        }
      }
    }
  }
}

.gallery-thumbnail {
  @for $i from 0 through 5 {
    &-#{$i} {
      grid-area: image-#{$i};
    }
  }

  &-video {
    position: relative;

    a {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
    }

    span {
      display: flex;
      background-color: rgba(color(blackColor), 0.4);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      transition: transition(default);
      position: absolute;
      cursor: pointer;
      z-index: 9;

      &:hover {
        background-color: rgba(color(blackColor), 0.2);
      }

      i {
        margin: 0;
        padding: 0;
        font-size: 60px;
        color: #f00;
        position: relative;

        &:before {
          position: relative;
          z-index: 2;
        }

        &:after {
          display: block;
          background: #fff;
          width: 30px;
          height: 30px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "";
          z-index: 1;
        }
      }
    }
  }
}
